import request from '@/utils/request'

// 登录
export function locationSearch(data) {
  return request({
    url: `/maps/location_search`,
    method: 'get',
    params: data
  })
}
